<template>
  <div class="index">
    <div class="banner1">
      <div class="data">
        <ul>
          <li>
            <div class="number_box"><span class="number">23</span><span class="year">+</span></div>
            <div class="describe1">接入院校</div>
          </li>
          <li>
            <div class="number_box"><span class="number">6235</span><span class="year">+</span></div>
            <div class="describe1">服务学生</div>
          </li>
          <li>
            <div class="number_box"><span class="number">3835</span><span class="year">+</span></div>
            <div class="describe1">管理设备</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content1">
      <div class="left" v-if="active==1">
        <img src="../../../assets/newIndex/zjsj_logo1.png" class="img1"/>
        <div style="margin-left: 15px; margin-bottom: 25px" >
          <div class="txt">
            北航学院基础实验中心物理平台，主要用于大学生物理设备的开发借用尝试，为接下来物理平台的设备共享中心项目做前期测试。
          </div>
          <div class="txt">目前正在探索双创等设备开发共享使用逻辑。</div>
        </div>
      </div>
      <div class="left" v-if="active==2">
        <img src="../../../assets/newIndex/zjsj_logo2.png" class="img2" />
        <div style="margin-left: 15px; margin-bottom: 25px">
          <div class="txt">
            人工智能实践创新平台项目建设阶段吸取过去实验中心项目建设在项目验收后的缺乏使用数据问题针对性的要求后续的实验实训项目需要在学生使用数据上有更多有价值的原始数据，打造开放共享的实验中心机房。
          </div>
          <div class="txt">为此作为人工智能实验箱的重要配套设备引入了2套实验通设备柜，目前已经投入使用。</div>
          <div class="txt">校方表示已经提升了现有实验中心数据化素材，有助于设备管理管控。</div>
        </div>
      </div>
      <div class="right">
        <img v-if="active==1" src="../../../assets/newIndex/fj.png" class="img" />
        <img v-if="active==2" src="../../../assets/newIndex/fj1.jpg" class="img" />
      </div>
    </div>
    <div  class="action" style="height:100px">
 <img @click="onClick1()" src="../../../assets/newIndex/p_left.png" style="margin-right:100px" class="img1" />
  <img @click="onClick1()" src="../../../assets/newIndex/p_right.png" class="img1" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  components: {
  },
  data() {
    return {
      active: 1,
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    onClick1() {
     this.active = this.active==1?2:1;
    },
  },
}
</script>
<style lang="less" scoped>
.index {
  display: contents;
  height: 100%;
  width: 100%;
  background: #fff !important;
  .banner1 {
    max-height: 600px;
    background-size: cover;
    min-height: 600px;
    /* background-size: 100% !important; */
    background-repeat: no-repeat;
    max-width: 1920px;
    background-size: cover !important;
    width: 100%;
    background: url('../../../assets/newIndex/practice_1_logo.png');
    .data {
      padding-top: 78px;
      width: 1200px;
      margin: 0 auto;
      padding-left: 100px;
      ul {
        margin: 0;
        padding: 0;
        li {
          margin-top: 35px;
          list-style: none;
          width: 185px;
        }
        li:last-child {
          margin-right: 0 !important;
        }
        .number_box {
          width: 285px;
          height: 80px;
          display: flex;
          align-items: end;
          .number {
            min-width: 36px;
            height: 81px;
            font-size: 67px;
            font-family: DIN-Regular, DIN;
            font-weight: 400;
            color: #07d5af;
            //line-height: 23px;
          }
          .year {
            min-width: 40px;
            height: 36px;
            font-size: 25px;
            font-family: Alibaba PuHuiTi 3-105 Heavy, Alibaba PuHuiTi 30;
            font-weight: normal;
            color: #07d5af;
            line-height: 23px;
          }
        }
        .describe1 {
          text-align: left;
          min-width: 62px;
          height: 22px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
          font-weight: normal;
          color: #000000;
        }
      }
    }
  }
  .content1 {
    width: 1100px;
    margin: 10px auto 30px auto;
    height: 553px;
    margin-top: 85px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 454px;
      height: 553px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img1 {
        width: 235px;
        height: 77px;
      }
      .img2 {
        width: 135px;
        height: 140px;
      }
      .txt {
        width: 448px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #000000;
        line-height: 34px;
      }
    }
    .right {
      width: 553px;
      height: 553px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .action{
   text-align: center;height: 100px;
   .img1{
     width:50px;
     height:50px;
   }
  }
}
ul li {
  list-style: none;
}
</style>