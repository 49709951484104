var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header-index-wide" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "总销售额",
                    total: "￥126,560"
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "trend",
                        {
                          staticStyle: { "margin-right": "16px" },
                          attrs: { flag: "up" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "term" }, slot: "term" },
                            [_vm._v("周同比")]
                          ),
                          _vm._v("\n            12%\n          ")
                        ]
                      ),
                      _c("trend", { attrs: { flag: "down" } }, [
                        _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                          _vm._v("日同比")
                        ]),
                        _vm._v("\n            11%\n          ")
                      ])
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("日均销售额"),
                    _c("span", [_vm._v("￥ 234.56")])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "订单量",
                    total: _vm._f("NumberFormat")(8846)
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c("div", [_c("mini-area")], 1),
                  _c("template", { slot: "footer" }, [
                    _vm._v("日订单量"),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm._f("NumberFormat")("1234")))
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "支付笔数",
                    total: _vm._f("NumberFormat")(6560)
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c("div", [_c("mini-bar", { attrs: { height: 40 } })], 1),
                  _c("template", { slot: "footer" }, [
                    _vm._v("转化率 "),
                    _c("span", [_vm._v("60%")])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 }
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "运营活动效果",
                    total: "78%"
                  }
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action"
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-progress", {
                        attrs: {
                          color: "rgb(19, 194, 194)",
                          target: 80,
                          percentage: 78,
                          height: 8
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "footer" },
                    [
                      _c(
                        "trend",
                        {
                          staticStyle: { "margin-right": "16px" },
                          attrs: { flag: "down" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "term" }, slot: "term" },
                            [_vm._v("同周比")]
                          ),
                          _vm._v("\n            12%\n          ")
                        ]
                      ),
                      _c("trend", { attrs: { flag: "up" } }, [
                        _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                          _vm._v("日环比")
                        ]),
                        _vm._v("\n            80%\n          ")
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card",
        {
          attrs: {
            loading: _vm.loading,
            bordered: false,
            "body-style": { padding: "0" }
          }
        },
        [
          _c(
            "div",
            { staticClass: "salesCard" },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": "1",
                    size: "large",
                    "tab-bar-style": {
                      marginBottom: "24px",
                      paddingLeft: "16px"
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extra-wrapper",
                      attrs: { slot: "tabBarExtraContent" },
                      slot: "tabBarExtraContent"
                    },
                    [
                      _c("div", { staticClass: "extra-item" }, [
                        _c("a", [_vm._v("今日")]),
                        _c("a", [_vm._v("本周")]),
                        _c("a", [_vm._v("本月")]),
                        _c("a", [_vm._v("本年")])
                      ]),
                      _c("a-range-picker", { style: { width: "256px" } })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { loading: "true", tab: "销售额" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c("bar", {
                                attrs: {
                                  title: "销售额排行",
                                  dataSource: _vm.barData
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c("rank-list", {
                                attrs: {
                                  title: "门店销售排行榜",
                                  list: _vm.rankList
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "销售趋势" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c("bar", {
                                attrs: {
                                  title: "销售额趋势",
                                  dataSource: _vm.barData
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 }
                            },
                            [
                              _c("rank-list", {
                                attrs: {
                                  title: "门店销售排行榜",
                                  list: _vm.rankList
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  style: { marginTop: "24px" },
                  attrs: {
                    loading: _vm.loading,
                    bordered: false,
                    title: "最近一周访问量统计"
                  }
                },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c("head-info", {
                            attrs: {
                              title: "今日IP",
                              content: _vm.loginfo.todayIp
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "a-spin",
                            { staticClass: "circle-cust" },
                            [
                              _c("a-icon", {
                                staticStyle: { "font-size": "24px" },
                                attrs: {
                                  slot: "indicator",
                                  type: "environment"
                                },
                                slot: "indicator"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c("head-info", {
                            attrs: {
                              title: "今日访问",
                              content: _vm.loginfo.todayVisitCount
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "a-spin",
                            { staticClass: "circle-cust" },
                            [
                              _c("a-icon", {
                                staticStyle: { "font-size": "24px" },
                                attrs: { slot: "indicator", type: "team" },
                                slot: "indicator"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c("head-info", {
                            attrs: {
                              title: "总访问量",
                              content: _vm.loginfo.totalVisitCount
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "a-spin",
                            { staticClass: "circle-cust" },
                            [
                              _c("a-icon", {
                                staticStyle: { "font-size": "24px" },
                                attrs: { slot: "indicator", type: "rise" },
                                slot: "indicator"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("line-chart-multid", {
                    attrs: {
                      fields: _vm.visitFields,
                      dataSource: _vm.visitInfo
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }