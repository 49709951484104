var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _vm._m(0),
    _c("div", { staticClass: "content1" }, [
      _vm.active == 1
        ? _c("div", { staticClass: "left" }, [
            _c("img", {
              staticClass: "img1",
              attrs: { src: require("../../../assets/newIndex/zjsj_logo1.png") }
            }),
            _vm._m(1)
          ])
        : _vm._e(),
      _vm.active == 2
        ? _c("div", { staticClass: "left" }, [
            _c("img", {
              staticClass: "img2",
              attrs: { src: require("../../../assets/newIndex/zjsj_logo2.png") }
            }),
            _vm._m(2)
          ])
        : _vm._e(),
      _c("div", { staticClass: "right" }, [
        _vm.active == 1
          ? _c("img", {
              staticClass: "img",
              attrs: { src: require("../../../assets/newIndex/fj.png") }
            })
          : _vm._e(),
        _vm.active == 2
          ? _c("img", {
              staticClass: "img",
              attrs: { src: require("../../../assets/newIndex/fj1.jpg") }
            })
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "action", staticStyle: { height: "100px" } }, [
      _c("img", {
        staticClass: "img1",
        staticStyle: { "margin-right": "100px" },
        attrs: { src: require("../../../assets/newIndex/p_left.png") },
        on: {
          click: function($event) {
            return _vm.onClick1()
          }
        }
      }),
      _c("img", {
        staticClass: "img1",
        attrs: { src: require("../../../assets/newIndex/p_right.png") },
        on: {
          click: function($event) {
            return _vm.onClick1()
          }
        }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner1" }, [
      _c("div", { staticClass: "data" }, [
        _c("ul", [
          _c("li", [
            _c("div", { staticClass: "number_box" }, [
              _c("span", { staticClass: "number" }, [_vm._v("23")]),
              _c("span", { staticClass: "year" }, [_vm._v("+")])
            ]),
            _c("div", { staticClass: "describe1" }, [_vm._v("接入院校")])
          ]),
          _c("li", [
            _c("div", { staticClass: "number_box" }, [
              _c("span", { staticClass: "number" }, [_vm._v("6235")]),
              _c("span", { staticClass: "year" }, [_vm._v("+")])
            ]),
            _c("div", { staticClass: "describe1" }, [_vm._v("服务学生")])
          ]),
          _c("li", [
            _c("div", { staticClass: "number_box" }, [
              _c("span", { staticClass: "number" }, [_vm._v("3835")]),
              _c("span", { staticClass: "year" }, [_vm._v("+")])
            ]),
            _c("div", { staticClass: "describe1" }, [_vm._v("管理设备")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-left": "15px", "margin-bottom": "25px" } },
      [
        _c("div", { staticClass: "txt" }, [
          _vm._v(
            "\n           北航学院基础实验中心物理平台，主要用于大学生物理设备的开发借用尝试，为接下来物理平台的设备共享中心项目做前期测试。\n         "
          )
        ]),
        _c("div", { staticClass: "txt" }, [
          _vm._v("目前正在探索双创等设备开发共享使用逻辑。")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-left": "15px", "margin-bottom": "25px" } },
      [
        _c("div", { staticClass: "txt" }, [
          _vm._v(
            "\n           人工智能实践创新平台项目建设阶段吸取过去实验中心项目建设在项目验收后的缺乏使用数据问题针对性的要求后续的实验实训项目需要在学生使用数据上有更多有价值的原始数据，打造开放共享的实验中心机房。\n         "
          )
        ]),
        _c("div", { staticClass: "txt" }, [
          _vm._v(
            "为此作为人工智能实验箱的重要配套设备引入了2套实验通设备柜，目前已经投入使用。"
          )
        ]),
        _c("div", { staticClass: "txt" }, [
          _vm._v(
            "校方表示已经提升了现有实验中心数据化素材，有助于设备管理管控。"
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }